<template>
  <div style="height: 85vh; overflow: hidden; overflow-y: scroll">
    <div class="flex bewteen aline_center center wrap" style="padding: 20px 0">
      <el-form ref="form" :model="form" label-width="80px" class="flex" >
        <div class="flex bewteen aline_center center wrap">
          <div style='display: flex; align-items: center; justify-content: space-between; width: 100%;'>
            <div style="min-width:30%">
              <el-form-item label="搜索：" label-position="left">
                <el-input
                  v-model="form.name"
                  placeholder="请输入产品名"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="拟融资额度（万）：" label-width="200px">
                <el-col :span="11">
                  <el-input
                    v-model="form.min_quota"
                    placeholder="请输入数字"
                  ></el-input>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                  <el-input
                    v-model="form.max_quota"
                    placeholder="请输入数字"
                  ></el-input>
                </el-col>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="匹配时间：" label-width="200px">
                  <el-col :span="11">
                      <el-form-item prop="date1">
                          <el-date-picker type="date" placeholder="选择日期" v-model="form.startTime" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                  </el-col>
                  <el-col class="line" :span="2">-</el-col>
                  <el-col :span="11">
                      <el-form-item prop="date2">
                          <el-date-picker type="date" placeholder="选择日期" v-model="form.endTime" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                  </el-col>
              </el-form-item>
            </div>
          </div>
          <div style="width:30%">
            <el-form-item label="所在地区：">
              <el-select v-model="form.region_id" placeholder="请选择" clearable @clear='clearClass' style="width:100%">
                <el-option v-for="item in options9" :value="item.name" @click.native='setAddressId(item.id)'>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="flex:1;text-align:left">
            <el-form-item>
                <el-button type="primary" @click.native='searchData'>搜索</el-button>
                
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div>
        <el-table :data="listsItem" style="width: 100%" :header-cell-style="{background:'#e5f3ff',color:'#1A7FD7'}">
            <el-table-column prop="mark" label="编号"> </el-table-column>
            <el-table-column prop="company_name" label="企业名称"> </el-table-column>
            <el-table-column prop="quota" label="拟融资额度（万元）" class-name="red">
            </el-table-column>
            <el-table-column prop="region_name" label="地区"> </el-table-column>
            <el-table-column prop="capital_comment" label="资金用途"> </el-table-column>
            <el-table-column prop="cate_name" label="担保方式"> </el-table-column>
            <el-table-column prop="create_time" label="发布时间"> </el-table-column>
            <el-table-column prop="status" label="当前状态"> </el-table-column>
            <el-table-column
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click='$throttle(getPhone)'>获取客服电话<span class="share" id='share'></span></el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pool_reportscreen">
          <div class="pool_footer">
              <div class="pool_mar">
                  <app-mypaginations :pagSize='paginations' :allData='poolList' @setHandleCurrent='handleCurrent' @setHandleSize='handleSize' @setPage='setPage' @setSize='setSize' />
                </div>
              </div>
        </div>
    </div>
  </div>
</template>

<script>
  import MyPaginations from '@/components/MyPaginations/mypaginations.vue'

export default {
  components: {
    'app-mypaginations': MyPaginations
  },
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
      },
      cityList: [],
      cityListProps: {
           value: "id", //匹配响应数据中的id
           label: "name", //匹配响应数据中的name
           children: "chilren", //匹配响应数据中的children
      },
      form: {
        name: "",
        min_quota: '',
        max_quota: '',
        startTime: '',
        endTime: '',
        region_id: ''
      },
      value: [],
      options: [],
      dialogFormVisible:false,
      listsItem: [],
      count: 0,
      poolList: [],
      options9: [],
      addressId: ''
    }
  },
  methods: {
    getPhone() {
      let data = {token: localStorage.eleToken ? localStorage.eleToken : ''}

      this.$post('obtain', data).then(res => {
        if(res.data.status == 1) {
          this.$alert(res.data.result, '客服电话', {
            confirmButtonText: '点击复制',
            callback: action => {
              if(action == 'confirm') {
                this.downque(res.data.result)
              }
              if(action == 'cancel'){}
            }
          })
        }
      })
    },
    //点击复制
    downque(path) {
            let text = path
            let textarea = document.createElement("textarea") //创建input对象
            let currentFocus = document.activeElement //当前获得焦点的元素
            
            let toolBoxwrap = document.getElementById('share') //将文本框插入到NewsToolBox这个之后
            let flag = ""
            toolBoxwrap.appendChild(textarea) //添加元素
            textarea.value = text
            textarea.focus()
            if (textarea.setSelectionRange) {
              textarea.setSelectionRange(0, textarea.value.length) //获取光标起始位置到结束位置
            } else {
              textarea.select()
            }
            try {
              flag = document.execCommand("copy") //执行复制
            } catch (eo) {
              flag = false
            }
            toolBoxwrap.removeChild(textarea) //删除元素
            currentFocus.focus()
            if(flag) {
              this.$message({
                    message: '复制成功!',
                    type: 'success',
                    duration: 1000
                  })
                  this.centerDialogVisible = false
            }else {
              this.$message({
                    message: '复制失败!',
                    type: 'error',
                    duration: 1000
                  })
            }
    },
    // 适用地区
    getQhCityList() {
      let data = {}

      this.$post('/qh_city_lst', data).then(res => {
        this.options9 = res.data.result ? res.data.result : []
      })
    },
    clearClass() {
      this.addressId = ''

      this.searchData()
    },
    setAddressId(id) {
      this.addressId = id

      this.searchData()
    },
    setPage(data) {
      this.paginations.page_index = data
    },
    setSize(data) {
      this.paginations.page_index = 1
      this.paginations.page_size = data
    },
    handleCurrent(item) {
      this.listsItem = item
    },
    handleSize(item) {
      this.listsItem = item
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count
      // 设置默认分页数据
      this.listsItem = this.poolList.filter((item, index) => {
        return index < this.paginations.page_size
      })
    },
    searchData() {
      this.paginations.page_index = 1

      this.getData()
    },
    getData() {
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : '',
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        startTime: this.form.startTime == '' ? '' : this.$turnTime(this.form.startTime),
        endTime: this.form.endTime == '' ? '' : this.$turnTime(this.form.endTime),
        region_id: this.addressId,
        name: this.form.name
      }

      console.log('data', data)

      this.$post('/demand_list', data).then(res => {
         this.poolList = res.data.result == '' ? [] : res.data.result

         this.poolList.map(item => {
            if(item.status == 1) {
              item.status = '待匹配'
            }

            if(item.status == 2) {
              item.status = '已匹配'
            }
         })
         this.count = res.data.count ? res.data.count : 0

         this.resetPaginations()
      })
    }
  },
  mounted() {
    this.getQhCityList()
    this.getData()
  }
}
</script>

<style scoped>
/*分页*/
.pool_reportscreen{
 /* position: absolute;
  bottom: 0.5%;
  width: 100%;*/
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
}
.pool_footer{
  min-width: 402px;
  height: 45px;
  background-color: #fff;
  display: flex;
    align-items: center;
}
.pool_mar{
  width: 100%;
}
.el-pagination{
  display: flex;
}
.el-pagination .el-select .el-input{
  height: 100%;
}
.el-select{
  height: 100%;
}
.el-pagination__jump{
  display: flex !important;
}

/deep/.red{
   color: #FF9D00;
}

</style>